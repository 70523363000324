<template>
	<div>
		<!-- 面包屑 -->
		<Breadcrumb></Breadcrumb>
		<el-card class="mt-2">
			<el-table
			    :data="list"
				:span-method="arraySpanMethod"
			    border
			    style="width: 100%">
			    <el-table-column
			      prop="nianji"
			      label="年级"
				  align="center"
			      width="180">
			    </el-table-column>
			    <el-table-column
			      prop="number"
			      label="序号"
				  align="center"
			      width="180">
			    </el-table-column>
			    <el-table-column
			      prop="t_code"
				  align="center"
			      label="教师编码（饭卡号）">
			    </el-table-column>
				<el-table-column
				  prop="t_name"
				  align="center"
				  label="教师姓名">
				</el-table-column>
				<el-table-column
				  prop="zksl"
				  align="center"
				  label="周课时量">
				</el-table-column>
				<el-table-column
				  prop="njz_score"
				  align="center"
				  label="年级组考核初始得分">
				</el-table-column>
				<el-table-column
				  prop="jwc_score"
				  align="center"
				  label="教务处考核最终得分（大于等于15分的都给15分，其它不变。）">
				</el-table-column>
			  </el-table>
		</el-card>
	</div>
</template>

<script>
	import common from "@/common/mixins/common.js"
	import Breadcrumb from '@/components/Breadcrumb';
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			Breadcrumb,
		},
		data() {
			return {
				preUrl: 'dkl',
				autoRequest: false,
				id:'',
				s_id:'',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				data:{},
				list:[],
				list_length:0,
			}
		},
		mounted() {
			if(this.$route.query.id){
				this.id = this.$route.query.id
			}
			if(this.$route.query.id){
				this.s_id = this.$route.query.s_id
			}
			this.getData()
		},
		computed:{
			
		},
		methods:{
			getData(){
				this.axios.get('/manage/'+this.preUrl+'/view',{
					params:{
						id:this.id
					}
				}).then(res=>{
					if(res.status){
						this.data = res.data
						this.list = res.data.list
						this.list_length = this.list.length
						this.list.push({nianji:'合计',number:'人数',t_code:'周课时量合计',t_name:'年级组考核分合计',zksl:'教务处考核分合计',njz_score:'',jwc_score:''})
						this.list.push({nianji:this.data.people_num,number:this.data.zksl_num,t_code:this.data.njz_score_count,t_name:this.data.jwc_score_count,zksl:'',njz_score:'',jwc_score:''})
						this.list.push({nianji:'班级数',number:'周总课时数',t_code:'系数（高一、高二0.8，高三1）',t_name:'年级部总分=班级总数×35×系数',zksl:'',njz_score:'',jwc_score:''})
						this.list.push({nianji:this.data.bj_num,number:this.data.zzkss,t_code:this.data.xs,t_name:this.data.njb_score,zksl:'',njz_score:'',jwc_score:''})
						this.list.push({nianji:'“得分”=个人周课时量÷全年级周课时量合计×年级组总分'})
					}
				})
			},
			arraySpanMethod({ row, column, rowIndex, columnIndex }) {
				if(rowIndex === this.list_length){
					if(columnIndex === 0){
						return [2, 2];
					}
					if(columnIndex === 1){
						return [1, 2];
					}
				}
				if(rowIndex === this.list_length+1){
					if(columnIndex === 0){
						return [1, 2];
					}
				}
				if(rowIndex === this.list_length+2){
					if(columnIndex === 0){
						return [1, 2];
					}
					if(columnIndex === 2){
						return [1, 2];
					}
					if(columnIndex === 3){
						return [1, 2];
					}
				}
				if(rowIndex === this.list_length+3){
					if(columnIndex === 0){
						return [1, 2];
					}
					if(columnIndex === 2){
						return [1, 2];
					}
					if(columnIndex === 3){
						return [1, 2];
					}
				}
				if(rowIndex === this.list_length+4){
					if(columnIndex === 0){
						return [1, 7];
					}
				}
			},
		}
	}
</script>

<style>
</style>